import React, { useContext, useMemo } from 'react';
import { ResolutionEnum, SitePerforAnalysisYearContext } from '../..';
import styles from './index.module.scss';
import tittleDecoration from '../../imgs/tittleDecoration.png';
import ReactEcharts from 'echarts-for-react';
import { Empty } from '@maxtropy/components';
import { Spin } from 'antd';
import { useRequest } from 'ahooks';
import { apiV2PvPerformanceAnalysePowerContrastPost } from '@maxtropy/dmes-apis-v2';
import { getChartOption } from './chart';
import pvIcon from '@/assets/images/pvIcon.png';
import { formatArea, formatMonth } from '../../utils';

const AreaComparison = () => {
  const { pvId, resolution, date, pvName } = useContext(SitePerforAnalysisYearContext);

  // 各片区发电量对比
  const { data: chartData, loading } = useRequest(
    () => {
      return apiV2PvPerformanceAnalysePowerContrastPost({
        id: pvId,
        type: resolution,
        time: date?.valueOf(),
      });
    },
    {
      ready: !!pvId && !!date && !!resolution,
      refreshDeps: [pvId, date, resolution],
    }
  );

  const isShowEmpty = useMemo(() => {
    if ((chartData?.areaPowerList ?? []).length) {
      return false;
    }
    return true;
  }, [chartData]);

  // 饼图
  const chartOption = useMemo(() => getChartOption(chartData?.areaPowerList), [chartData]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
        <span className={styles.title}>各片区发电量对比</span>
      </div>
      <div className={styles.content_box}>
        <div className={styles.chart_box}>
          {loading ? (
            <Spin spinning={true} />
          ) : isShowEmpty ? (
            <Empty className={styles.empty_box} />
          ) : (
            <ReactEcharts style={{ height: 240 }} option={chartOption} notMerge lazyUpdate={false} />
          )}
        </div>
        <div className={styles.desc_box}>
          <div className={styles.line}>
            对于
            <div className={styles.name_box}>
              <img src={pvIcon} alt="" style={{ width: 16, height: 16 }} />
              {pvName}
            </div>
          </div>
          <div className={styles.line}>
            时间上看，
            {resolution === ResolutionEnum.YEAR ? date?.format('YYYY') + '年' : date?.format('YYYY-MM') + '月'}
            {formatMonth(chartData?.maxTime, resolution)}发电量<span className={styles.peak_label}>最多</span>，
            {formatMonth(chartData?.minTime, resolution)}发电量<span className={styles.peak_label}>最少</span>，
            {formatMonth(chartData?.increaseTimes, resolution)}发电量同比去年有
            <span className={styles.peak_label}>增加</span>，{formatMonth(chartData?.decreaseTimes, resolution)}
            发电量同比去年有
            <span className={styles.peak_label}>减少</span>
          </div>
          <div className={styles.line}>
            片区上看，
            {resolution === ResolutionEnum.YEAR ? date?.format('YYYY') + '年' : date?.format('YYYY-MM') + '月'}
            {formatArea(chartData?.maxAreaPower)}发电
            <span className={styles.peak_label}>最多</span>，片区{formatArea(chartData?.minAreaPower)}发电
            <span className={styles.peak_label}>最少</span>，{formatArea(chartData?.increaseAreas)}电量同比去年有
            <span className={styles.peak_label}>增加</span>，{formatArea(chartData?.decreaseAreas)}同比去年有
            <span className={styles.peak_label}>减少</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaComparison;
