import { V2PvPerformanceAnalysePowerContrastPostResponse } from '@maxtropy/dmes-apis-v2';
import PieOuterImg from '../../imgs/circlebg-big@2x.png';
import circleImg from '../../imgs/circle@2x.png';
import { keepTwoOrLine } from '../../utils';

export const getChartOption = (chartData: V2PvPerformanceAnalysePowerContrastPostResponse['areaPowerList']) => {
  const totalPower = chartData?.reduce((pre, cur) => (cur.power || 0) + pre, 0);
  return {
    // color: ['#FFCB47', '#16DD8E', '#2D8DFF', '#556583'],
    legend: {
      left: '40%',
      top: 'center',
      orient: 'vertical',
      icon: 'circle',
      itemGap: 8,
      itemHeight: 7,
      itemWidth: 7,
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
        rich: {
          name: {
            fontSize: 14,
            lineHeight: 20,
            width: 220,
          },
          percent: {
            fontSize: 14,
            width: 100,
          },
          value: {
            fontSize: 14,
            width: 100,
          },
        },
      },
      formatter: (name: string) => {
        const item = chartData?.find(item => item.areaName === name);
        const percent = totalPower ? ((item?.power ?? 0) / totalPower).toFixed(2) + '%' : '-';
        return `{name| ${item?.areaName}:}{percent| ${percent ?? '-'}}{value|${keepTwoOrLine(item?.power)}}`;
      },
    },
    title: {
      text: (chartData ?? []).length ? chartData?.reduce((pre, cur) => (cur.power || 0) + pre, 0).toFixed(2) : '-',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 14,
        lineHeight: 22,
      },
      subtext: '发电量(kWh)',
      subtextStyle: {
        color: 'rgba(255,255,255,0.65)',
        fontSize: 12,
      },
      left: 208,
      top: 98,
      itemGap: 8,
      textAlign: 'center',
    },
    graphic: {
      elements: [
        // {
        //   type: 'image',
        //   style: {
        //     image: PieOuterImg,
        //     left: '10%',
        //     // x: '10%',
        //     y: 60,
        //     width: 132,
        //     height: 132,
        //   },
        // },
        {
          type: 'image',
          style: {
            image: circleImg,
            x: 156,
            y: 66,
            width: 112,
            height: 112,
          },
        },
      ],
    },
    series: [
      {
        type: 'pie',
        name: '发电量(kWh)',
        percentPrecision: 5,
        minAngle: 2,
        padAngle: 1,
        radius: [58, 66],
        left: 150,
        top: 60,
        width: 124,
        height: 124,
        label: {
          show: false,
        },
        data: chartData?.map(item => ({
          name: item.areaName,
          value: keepTwoOrLine(item.power),
        })),
      },
      {
        data: chartData?.map(item => ({
          name: item.areaName,
          value: keepTwoOrLine(item.power),
        })),
        type: 'pie',
        percentPrecision: 5,
        padAngle: 1,
        minAngle: 2,
        left: 150,
        top: 60,
        name: '发电量(kWh)',
        radius: [66, 86],
        width: 124,
        height: 124,
        emphasis: {
          disabled: true,
        },
        label: {
          show: false,
        },
        itemStyle: {
          opacity: 0.2,
        },
      },
    ],
  };
};
