import { V2BsaDataPredictElectricPostResponse, V2PvPerformanceAnalysePowerPostResponse } from '@maxtropy/dmes-apis-v2';
import { keepPercentTwoNull, keepTwoNull } from '../../utils';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import { isNil } from 'lodash-es';
import { ResolutionEnum } from '../..';
import { ActiveKey } from '.';

// 图例
export const formatLegend = (activeKey?: ActiveKey) => {
  if (activeKey === ActiveKey.REALITY) {
    return [
      {
        right: 80,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.45)',
        itemWidth: 12,
        itemHeight: 12,
        data: [
          {
            name: '逆变器发电量',
            icon: 'rect',
          },
          {
            name: '并网点发电量',
            icon: 'rect',
          },
        ],
      },
      {
        right: 10,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.45)',
        itemWidth: 16,
        itemHeight: 8,
        data: [
          {
            name: '辐射量',
            icon: 'path://M0 426.666667h1024v170.666666H0z',
          },
        ],
      },
    ];
  } else if (activeKey === ActiveKey.THEORY) {
    return [
      {
        right: 114,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.45)',
        itemWidth: 12,
        itemHeight: 12,
        data: [
          {
            name: '逆变器发电量',
            icon: 'rect',
          },
          {
            name: '理论发电量',
            icon: 'rect',
          },
        ],
      },
      {
        right: 10,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.45)',
        itemWidth: 16,
        itemHeight: 8,
        data: [
          {
            name: '系统效率(PR)',
            icon: 'path://M0 426.666667h1024v170.666666H0z',
          },
        ],
      },
    ];
  } else {
    return [
      {
        right: 80,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.45)',
        itemWidth: 12,
        itemHeight: 12,
        data: [
          {
            name: '并网点发电量',
            icon: 'rect',
          },
          {
            name: '计划发电量',
            icon: 'rect',
          },
        ],
      },
      {
        right: 10,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.45)',
        itemWidth: 16,
        itemHeight: 8,
        data: [
          {
            name: '完成率',
            icon: 'path://M0 426.666667h1024v170.666666H0z',
          },
        ],
      },
    ];
  }
};

// 通过activeKey展示不同series
export const formatSeries = (
  activeKey?: string,
  allData?: {
    inverterPowerData: (number | null)[][];
    gridConnectedPowerData: (number | null)[][];
    cumulativeTiltTotalRadiationData: (string | number | null)[][];
    theoreticalPowerGenerationData: (number | null)[][];
    systemEfficiencyData: (string | number | null)[][];
    planPowerGenerationData: (number | null)[][];
    accomplishmentRatioData: (string | number | null)[][];
  }
) => {
  if (activeKey === ActiveKey.REALITY) {
    return [
      {
        name: '逆变器发电量',
        type: 'bar',
        yAxisIndex: 0,
        data: allData?.inverterPowerData ?? [],
      },
      {
        name: '并网点发电量',
        type: 'bar',
        yAxisIndex: 0,
        data: allData?.gridConnectedPowerData ?? [],
      },
      {
        name: '辐射量',
        type: 'line',
        data: allData?.cumulativeTiltTotalRadiationData ?? [],
        yAxisIndex: 1,
        connectNulls: true,
        symbol: 'circle',
        symbolSize: 2,
        showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          // symbol样式
          normal: {
            borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
            borderWidth: 6, // symbol边框宽度
          },
        },
      },
    ];
  } else if (activeKey === ActiveKey.THEORY) {
    return [
      {
        name: '逆变器发电量',
        type: 'bar',
        data: allData?.inverterPowerData ?? [],
        yAxisIndex: 0,
      },
      {
        name: '理论发电量',
        type: 'bar',
        data: allData?.theoreticalPowerGenerationData ?? [],
        yAxisIndex: 0,
      },
      {
        name: '系统效率(PR)',
        type: 'line',
        data: allData?.systemEfficiencyData ?? [],
        yAxisIndex: 1,
        connectNulls: true,
        symbol: 'circle',
        symbolSize: 2,
        showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          // symbol样式
          normal: {
            borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
            borderWidth: 6, // symbol边框宽度
          },
        },
      },
    ];
  } else {
    return [
      {
        name: '并网点发电量',
        type: 'bar',
        data: allData?.gridConnectedPowerData ?? [],
        yAxisIndex: 0,
      },
      {
        name: '计划发电量',
        type: 'bar',
        data: allData?.planPowerGenerationData ?? [],
        yAxisIndex: 0,
      },
      {
        name: '完成率',
        type: 'line',
        data: allData?.accomplishmentRatioData ?? [],
        yAxisIndex: 1,
        connectNulls: true,
        symbol: 'circle',
        symbolSize: 2,
        showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          // symbol样式
          normal: {
            borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
            borderWidth: 6, // symbol边框宽度
          },
        },
      },
    ];
  }
};

// 通过不同activeKey展示不同间隔
export const calcIntervalValue = (
  activeKey?: string,
  allData?: {
    inverterPowerData: (number | null)[][];
    gridConnectedPowerData: (number | null)[][];
    cumulativeTiltTotalRadiationData: (string | number | null)[][];
    theoreticalPowerGenerationData: (number | null)[][];
    systemEfficiencyData: (string | number | null)[][];
    planPowerGenerationData: (number | null)[][];
    accomplishmentRatioData: (string | number | null)[][];
  }
) => {
  const maxInverterPowerData = Math.max(...(allData?.inverterPowerData ?? []).map(i => i[1] ?? 0)); // 逆变器发电量
  const minInverterPowerData = Math.min(...(allData?.inverterPowerData ?? []).map(i => i[1] ?? 0)); // 逆变器发电量
  if (activeKey === ActiveKey.REALITY) {
    const maxGridConnectedPowerData = Math.max(...(allData?.gridConnectedPowerData ?? []).map(i => i[1] ?? 0)); // 并网点发电量
    const minGridConnectedPowerData = Math.min(...(allData?.gridConnectedPowerData ?? []).map(i => i[1] ?? 0)); // 并网点发电量
    const max = Math.ceil(Math.max(maxInverterPowerData, maxGridConnectedPowerData) / 2) * 2;
    const min = Math.floor(Math.min(minInverterPowerData, minGridConnectedPowerData) / 2) * 2;
    // 间隔计算
    const interVal = Number(((max - min) / 4).toFixed(0));
    return { max, min, interVal };
  } else if (activeKey === ActiveKey.THEORY) {
    const maxTheoreticalPowerGenerationData = Math.max(
      ...(allData?.theoreticalPowerGenerationData ?? []).map(i => i[1] ?? 0)
    ); // 理论发电量
    const minTheoreticalPowerGenerationData = Math.min(
      ...(allData?.theoreticalPowerGenerationData ?? []).map(i => i[1] ?? 0)
    ); // 理论发电量
    const max = Math.ceil(Math.max(maxInverterPowerData, maxTheoreticalPowerGenerationData) / 2) * 2;
    const min = Math.floor(Math.min(minInverterPowerData, minTheoreticalPowerGenerationData) / 2) * 2;
    // 间隔计算
    const interVal = Number(((max - min) / 4).toFixed(0));
    return { max, min, interVal };
  } else {
    const maxGridConnectedPowerData = Math.max(...(allData?.gridConnectedPowerData ?? []).map(i => i[1] ?? 0)); // 并网点发电量
    const minGridConnectedPowerData = Math.min(...(allData?.gridConnectedPowerData ?? []).map(i => i[1] ?? 0)); // 并网点发电量

    const maxPlanPowerGenerationData = Math.max(...(allData?.planPowerGenerationData ?? []).map(i => i[1] ?? 0)); // 计划发电量
    const minPlanPowerGenerationData = Math.min(...(allData?.planPowerGenerationData ?? []).map(i => i[1] ?? 0)); // 计划发电量、

    const max = Math.ceil(Math.max(maxGridConnectedPowerData, maxPlanPowerGenerationData) / 2) * 2;
    const min = Math.floor(Math.min(minGridConnectedPowerData, minPlanPowerGenerationData) / 2) * 2;
    // 间隔计算
    const interVal = Number(((max - min) / 4).toFixed(0));
    return { max, min, interVal };
  }
};
// 通过不同activeKey展示右侧Y轴不同间隔
export const calcPercentIntervalValue = (
  activeKey?: string,
  allData?: {
    inverterPowerData: (number | null)[][];
    gridConnectedPowerData: (number | null)[][];
    cumulativeTiltTotalRadiationData: (string | number | null)[][];
    theoreticalPowerGenerationData: (number | null)[][];
    systemEfficiencyData: (string | number | null)[][];
    planPowerGenerationData: (number | null)[][];
    accomplishmentRatioData: (string | number | null)[][];
  }
) => {
  if (activeKey === ActiveKey.REALITY) {
    // 辐射量最大最小间隔计算
    const maxRadiationData = Math.max(...(allData?.cumulativeTiltTotalRadiationData ?? []).map((i: any) => i[1] ?? 0)); // 辐射量
    const minRadiationData = Math.min(...(allData?.cumulativeTiltTotalRadiationData ?? []).map((i: any) => i[1] ?? 0)); // 辐射量
    const maxPercent = Math.ceil(Math.max(maxRadiationData));
    const minPercent = Math.floor(Math.min(minRadiationData));
    const percentInterVal = Number(((maxPercent - minPercent) / 4).toFixed(0));
    return { maxPercent, minPercent, percentInterVal };
  } else if (activeKey === ActiveKey.THEORY) {
    const maxSystemEfficiencyData = Math.max(...(allData?.systemEfficiencyData ?? []).map((i: any) => i[1] ?? 0)); // 辐射量
    const minSystemEfficiencyData = Math.min(...(allData?.systemEfficiencyData ?? []).map((i: any) => i[1] ?? 0)); // 辐射量
    const maxPercent = Math.ceil(Math.max(maxSystemEfficiencyData));
    const minPercent = Math.floor(Math.min(minSystemEfficiencyData));
    const percentInterVal = Number(((maxPercent - minPercent) / 4).toFixed(0));
    return { maxPercent, minPercent, percentInterVal };
  } else {
    const maxAccomplishmentRatioData = Math.max(...(allData?.accomplishmentRatioData ?? []).map((i: any) => i[1] ?? 0)); // 辐射量
    const minAccomplishmentRatioData = Math.min(...(allData?.accomplishmentRatioData ?? []).map((i: any) => i[1] ?? 0)); // 辐射量
    const maxPercent = Math.ceil(Math.max(maxAccomplishmentRatioData));
    const minPercent = Math.floor(Math.min(minAccomplishmentRatioData));
    const percentInterVal = Number(((maxPercent - minPercent) / 4).toFixed(0));
    return { maxPercent, minPercent, percentInterVal };
  }
};

export const getChartOption = (
  chartData?: V2PvPerformanceAnalysePowerPostResponse,
  activeKey?: ActiveKey,
  resolution?: ResolutionEnum
) => {
  const formatDate = resolution === ResolutionEnum.YEAR ? 'YYYY-MM' : 'MM-DD';
  const YName =
    activeKey === ActiveKey.REALITY
      ? '辐射量 (MJ/m²)'
      : activeKey === ActiveKey.THEORY
      ? '系统效率(PR) (%)'
      : '完成率 (%)';
  const legendByActiveKey = formatLegend(activeKey);
  // 逆变器发电量
  let inverterPowerData = (chartData?.list ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepTwoNull(item.inverterPower),
  ]);
  // 并网点发电量
  let gridConnectedPowerData = (chartData?.list ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepTwoNull(item.gridConnectedPower),
  ]);
  // 累计倾斜总辐射(辐射量)
  let cumulativeTiltTotalRadiationData = (chartData?.list ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepTwoNull(item.cumulativeTiltTotalRadiation),
    '辐射量',
  ]);
  // 理论发电量
  let theoreticalPowerGenerationData = (chartData?.list ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepTwoNull(item.theoreticalPowerGeneration),
  ]);
  // 系统效率
  let systemEfficiencyData = (chartData?.list ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepPercentTwoNull(item.systemEfficiency),
    '系统效率',
  ]);
  // 计划发电量
  let planPowerGenerationData = (chartData?.list ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepTwoNull(item.planPowerGeneration),
  ]);
  // 完成率
  let accomplishmentRatioData = (chartData?.list ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepPercentTwoNull(item.accomplishmentRatio),
    '完成率',
  ]);

  const allData = {
    inverterPowerData,
    gridConnectedPowerData,
    cumulativeTiltTotalRadiationData,
    theoreticalPowerGenerationData,
    systemEfficiencyData,
    planPowerGenerationData,
    accomplishmentRatioData,
  };
  const series = formatSeries(activeKey, allData);
  const { max, min, interVal } = calcIntervalValue(activeKey, allData);
  const { maxPercent, minPercent, percentInterVal } = calcPercentIntervalValue(activeKey, allData);

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format(formatDate);
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data } = item;
          let unit = '';
          if (data[2] && (data[2] === '系统效率' || data[2] === '完成率')) {
            unit = '%';
          } else if (data[2] && data[2] === '辐射量') {
            unit = 'MJ/m²';
          } else {
            unit = 'kWh';
          }
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
            item.color
          };"></span>${seriesName} : &nbsp;${data[1] || data[1] === 0 ? data[1] + unit : '-'}</div>`;
          str = firstStr + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: 50,
      right: 20,
      top: 60,
      bottom: 40,
      containLabel: true,
    },
    color: [
      '#52E7FF',
      '#16DD8E',
      '#2D8DFF',
      '#FFCB47',
      '#854ECA',
      '#ABD335',
      '#FF6D2C',
      '#CE90D1',
      '#0DB6D9',
      '#FF477B',
    ],
    dataZoom: {
      type: 'slider',
      left: 20,
      right: 20,
      height: 20,
      bottom: 10,
      textStyle: {
        fontSize: 10,
      },
    },
    legend: legendByActiveKey,
    xAxis: [
      {
        type: 'time',
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (e: number) {
            return dayjs(e, 'x').format(formatDate);
          },
        },
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '电量（kWh）',
        // splitNumber: 5, //优先级太低只能硬算
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        max: max,
        min: min,
        interval: interVal,
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: '{value}',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
      {
        type: 'value',
        name: YName,
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        max: maxPercent,
        min: minPercent,
        interval: percentInterVal,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
    ],
    series: series,
  };
};
