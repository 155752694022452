import {
  V2PvPerformanceAnalyseInverterExpressionPostResponse,
  V2PvPerformanceAnalysePowerContrastPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { isNil } from 'lodash-es';
import { ResolutionEnum } from '.';
import dayjs, { Dayjs } from 'dayjs';

export const keepTwoNull = (data?: number): number | null => {
  if (!isNil(data)) {
    return Number(data.toFixed(2));
  } else {
    return null;
  }
};
export const keepPercentTwoNull = (data?: number): number | null => {
  if (!isNil(data)) {
    return Number((data * 100).toFixed(2));
  } else {
    return null;
  }
};

export const keepTwoOrLine = (data?: number) => {
  if (!isNil(data)) {
    return Number(data.toFixed(2));
  }
  return '--';
};

export const formatMonth = (
  increaseTimes?: V2PvPerformanceAnalysePowerContrastPostResponse['increaseTimes'],
  resolution?: ResolutionEnum
) => {
  const desc = resolution === ResolutionEnum.YEAR ? '月' : '号';
  if (increaseTimes?.length) {
    let str = '';
    increaseTimes.forEach((month, index) => {
      if (index === increaseTimes.length - 1) {
        str += month + desc;
      } else {
        str += month + desc + '、';
      }
    });
    return str;
  }
  return '-';
};

export const formatArea = (increaseAreas?: V2PvPerformanceAnalysePowerContrastPostResponse['increaseAreas']) => {
  if (increaseAreas?.length) {
    let str = '';
    increaseAreas.forEach((area, index) => {
      if (index === increaseAreas.length - 1) {
        str += area.areaName + '片区';
      } else {
        str += area.areaName + '片区、';
      }
    });
    return str;
  }
  return '-';
};

export const formatAreaName = (
  maxInverterPower?: V2PvPerformanceAnalyseInverterExpressionPostResponse['maxInverterPower']
) => {
  if (maxInverterPower?.length) {
    let str = '';
    maxInverterPower.forEach((area, index) => {
      if (index === maxInverterPower.length - 1) {
        str += area.inverterName;
      } else {
        str += area.inverterName + '和';
      }
    });
    return str;
  }
  return '-';
};

export const formatAreaNameDot = (
  maxInverterPower?: V2PvPerformanceAnalyseInverterExpressionPostResponse['maxInverterPower']
) => {
  if (maxInverterPower?.length) {
    let str = '';
    maxInverterPower.forEach((area, index) => {
      if (index === maxInverterPower.length - 1) {
        str += area.inverterName;
      } else {
        str += area.inverterName + '、';
      }
    });
    return str;
  }
  return '-';
};

// 年还是月
export const isYearOrMonth = (resolution?: ResolutionEnum) => {
  if (resolution === ResolutionEnum.YEAR) {
    return '年';
  }
  return '月';
};

// 发电低于10
export const formatPowerBelowTen = (
  belowInverters?: V2PvPerformanceAnalyseInverterExpressionPostResponse['belowInverters'],
  resolution?: ResolutionEnum,
  date?: Dayjs
) => {
  const list = sortclass(belowInverters);
  if (belowInverters?.length) {
    let str = '';
    list.forEach((itemList, index) => {
      if (index === list.length - 1) {
        str += `${itemList?.[0].stringValue}在${formateDate(itemList, resolution, date)}发电量低于10kWh`;
      } else {
        str += `${itemList?.[0].stringValue}在${formateDate(itemList, resolution, date)}发电量低于10kWh` + '、';
      }
    });
    return str;
  }
  return '-';
};

const formateDate = (
  itemList?: V2PvPerformanceAnalyseInverterExpressionPostResponse['belowInverters'],
  resolution?: ResolutionEnum,
  date?: Dayjs
) => {
  if (itemList?.length) {
    let str = '';
    const dateStr = resolution === ResolutionEnum.YEAR ? date?.format('YYYY') + '-' : date?.format('MM') + '-';
    const dateDesc = resolution === ResolutionEnum.YEAR ? '月' : '日';
    itemList.forEach((item, index) => {
      if (index === itemList.length - 1) {
        str += dateStr + item.longValue + dateDesc;
      } else {
        str += dateStr + item.longValue + dateDesc + '、';
      }
    });
    return str;
  }
  return '-';
};

export type PerformanceAnalyseInverterExpressionItemProps = Exclude<
  V2PvPerformanceAnalyseInverterExpressionPostResponse['belowInverters'],
  undefined
>[number];
// 相同逆变器名称进行分组
const sortclass = (sortData: V2PvPerformanceAnalyseInverterExpressionPostResponse['belowInverters']) => {
  const groupBy = (array: V2PvPerformanceAnalyseInverterExpressionPostResponse['belowInverters'], f: Function) => {
    const groups: {
      [key: string]: V2PvPerformanceAnalyseInverterExpressionPostResponse['belowInverters'];
    } = {};
    array?.forEach(item => {
      const group = JSON.stringify(f(item));
      groups[group] = groups[group] || [];
      (groups[group] ?? []).push(item);
    });
    return Object.keys(groups).map(group => {
      return groups[group];
    });
  };
  const sorted = groupBy(sortData, (item?: PerformanceAnalyseInverterExpressionItemProps) => {
    return item?.stringValue;
  });
  return sorted;
};
