import { V2PvPerformanceAnalyseInverterExpressionPostResponse } from '@maxtropy/dmes-apis-v2';
import { keepTwoNull, keepTwoOrLine } from '../../utils';
import dayjs from 'dayjs';
import { ResolutionEnum } from '../..';
import circleImg from '../../imgs/circle@2x.png';
import { time } from 'console';
import { isNil } from 'lodash-es';

// 图例
export const formatLegend = (
  inverterChartDataList?: V2PvPerformanceAnalyseInverterExpressionPostResponse['inverterChartDataList']
) => {
  return [
    {
      right: 20,
      textStyle: {
        color: 'rgba(255, 255, 255, 0.85)',
      },
      inactiveColor: 'rgba(255, 255, 255, 0.45)',
      itemWidth: 12,
      itemHeight: 12,
      data: inverterChartDataList?.map(item => ({
        name: item.inverterName,
        icon: 'rect',
      })),
    },
  ];
};

// 展示不同series
export const formatSeries = (
  chartData?: V2PvPerformanceAnalyseInverterExpressionPostResponse['inverterChartDataList']
) => {
  return [
    ...(chartData ?? []).map(item => ({
      name: item.inverterName,
      type: 'bar',
      yAxisIndex: 0,
      data: item.inverterChartDataList?.map(c => [dayjs(c.time).valueOf(), keepTwoNull(c.power), item.inverterName]),
    })),
    ...(chartData ?? []).map(item => ({
      name: item.inverterName,
      type: 'line',
      data: item.inverterChartDataList?.map(c => [
        dayjs(c.time).valueOf(),
        keepTwoNull(c.availableHour),
        item.inverterName,
      ]),
      yAxisIndex: 1,
      connectNulls: true,
      symbol: 'circle',
      symbolSize: 2,
      showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
      itemStyle: {
        // symbol样式
        normal: {
          borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
          borderWidth: 6, // symbol边框宽度
        },
      },
    })),
  ];
};

// 展示间隔
export const calcIntervalValue = (
  chartData?: V2PvPerformanceAnalyseInverterExpressionPostResponse['inverterChartDataList']
) => {
  // 逆变器发电量
  const inverterPowerData = chartData
    ?.map(item => {
      return item.inverterChartDataList?.map(item => [dayjs(item.time).valueOf(), keepTwoNull(item.power)]);
    })
    .flat();
  const maxInverterPowerData = Math.max(...(inverterPowerData ?? []).map(i => i?.[1] ?? 0)); // 逆变器发电量
  const minInverterPowerData = Math.min(...(inverterPowerData ?? []).map(i => i?.[1] ?? 0)); // 逆变器发电量

  const max = Math.ceil(Math.max(maxInverterPowerData));
  const min = Math.floor(Math.min(minInverterPowerData));
  // 间隔计算
  const interVal = Number(((max - min) / 4).toFixed(0));
  return { max, min, interVal };
};

export const getChartOption = (
  chartData?: V2PvPerformanceAnalyseInverterExpressionPostResponse,
  resolution?: ResolutionEnum
) => {
  const formatDate = resolution === ResolutionEnum.YEAR ? 'YYYY-MM' : 'MM-DD';
  const formatShowChartData = chartData?.inverterChartDataList?.slice(0, 8); // 取前七个展示，剩余用剩余展示
  const restChartData = chartData?.inverterChartDataList?.slice(8, chartData?.inverterChartDataList?.length);
  const finalChartData = [
    ...(formatShowChartData ?? []),
    {
      inverterName: '其他',
      inverterChartDataList: restChartData?.[0].inverterChartDataList?.map(item => ({
        time: item.time,
        power: restChartData
          .map(c => c.inverterChartDataList?.find(x => x.time === item.time)?.power)
          ?.reduce((pre, cur) => (cur || 0) + (pre || 0), 0),
        availableHour: restChartData
          .map(c => c.inverterChartDataList?.find(x => x.time === item.time)?.availableHour)
          ?.reduce((pre, cur) => (cur || 0) + (pre || 0), 0),
      })),
    },
  ];

  const legend = formatLegend(finalChartData);
  console.log('finalChartData', finalChartData);

  // 可用小时数间隔计算
  let availableHourData = chartData?.inverterChartDataList
    ?.map(item => {
      return item.inverterChartDataList?.map(item => [dayjs(item.time).valueOf(), keepTwoNull(item.availableHour)]);
    })
    .flat();
  const maxAvailableHourData = Math.max(...(availableHourData ?? []).map(i => i?.[1] ?? 0)); // 可用小时数
  const minAvailableHourData = Math.min(...(availableHourData ?? []).map(i => i?.[1] ?? 0)); // 可用小时数

  const maxAvailableHour = Math.ceil(Math.max(maxAvailableHourData));
  const minAvailableHour = Math.floor(Math.min(minAvailableHourData));
  // 可用小时数间隔计算
  const availableHourInterVal = Number(((maxAvailableHour - minAvailableHour) / 4).toFixed(0));

  const series = formatSeries(finalChartData);
  console.log('finalChartData', finalChartData);
  // 左侧Y轴计算
  const { max, min, interVal } = calcIntervalValue(finalChartData);

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format(formatDate);
        let otherStr = '';
        let str = '';
        items
          .filter((x: { seriesType: string }) => x?.seriesType === 'bar')
          .forEach((item: any) => {
            const { seriesName, data } = item;
            const availableTime = items
              .filter((x: any) => x?.seriesType === 'line')
              .find((c: any) => c?.data?.[2] === item?.data[2])?.data?.[1];
            const renderTime = !isNil(availableTime) ? availableTime + 'h' : '--';
            let unit = 'kWh';
            otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
              item.color
            };"></span>${seriesName} : &nbsp;${!isNil(data[1]) ? data[1] + unit : '--'} &nbsp;${renderTime}</div>`;
            str = firstStr + otherStr;
          });
        return str;
      },
    },
    grid: {
      left: 50,
      right: 20,
      top: 60,
      bottom: 40,
      containLabel: true,
    },
    dataZoom: {
      type: 'slider',
      left: 20,
      right: 20,
      height: 20,
      bottom: 10,
      textStyle: {
        fontSize: 10,
      },
    },
    legend: legend,
    xAxis: [
      {
        type: 'time',
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (e: number) {
            return dayjs(e, 'x').format(formatDate);
          },
        },
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '发电量（kWh）',
        // splitNumber: 5, //优先级太低只能硬算
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        max: max,
        min: min,
        interval: interVal,
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: '{value}',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
      {
        type: 'value',
        name: '可用小时数（h）',
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        max: maxAvailableHour,
        min: minAvailableHour,
        interval: availableHourInterVal,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
    ],
    series: series,
  };
};

export const getPieChartOption = (
  chartData?: V2PvPerformanceAnalyseInverterExpressionPostResponse['inverterDataList']
) => {
  const totalPower = chartData?.reduce((pre, cur) => (cur.power || 0) + pre, 0);
  const formatShowChartData = chartData?.slice(0, 8); // 取前七个展示，剩余用剩余展示
  const restChartData = chartData?.slice(8, chartData.length);
  const restTotalPower = restChartData?.reduce((pre, cur) => (cur.power || 0) + pre, 0);
  const finalChartData = [...(formatShowChartData ?? []), { inverterName: '其他', power: restTotalPower }];
  return {
    // color: ['#FFCB47', '#16DD8E', '#2D8DFF', '#556583'],
    legend: {
      left: '40%',
      top: 'center',
      orient: 'vertical',
      icon: 'circle',
      itemGap: 8,
      itemHeight: 7,
      itemWidth: 7,
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
        rich: {
          name: {
            fontSize: 14,
            lineHeight: 20,
            width: 220,
          },
          percent: {
            fontSize: 14,
            width: 100,
          },
          value: {
            fontSize: 14,
            width: 100,
          },
        },
      },
      formatter: (name: string) => {
        const item = finalChartData?.find(item => item.inverterName === name);
        const percent = totalPower ? ((item?.power ?? 0) / totalPower).toFixed(2) + '%' : '-';
        return `{name| ${item?.inverterName}:}{percent| ${percent ?? '-'}}{value|${keepTwoOrLine(item?.power)}}`;
      },
    },
    title: {
      text: (chartData ?? []).length ? chartData?.reduce((pre, cur) => (cur.power || 0) + pre, 0).toFixed(2) : '-',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 14,
        lineHeight: 22,
      },
      subtext: '发电量(kWh)',
      subtextStyle: {
        color: 'rgba(255,255,255,0.65)',
        fontSize: 12,
      },
      left: 208,
      top: 98,
      itemGap: 8,
      textAlign: 'center',
    },
    graphic: {
      elements: [
        {
          type: 'image',
          style: {
            image: circleImg,
            x: 156,
            y: 66,
            width: 112,
            height: 112,
          },
        },
      ],
    },
    series: [
      {
        type: 'pie',
        name: '发电量(kWh)',
        percentPrecision: 5,
        minAngle: 2,
        padAngle: 1,
        radius: [58, 66],
        left: 150,
        top: 60,
        width: 124,
        height: 124,
        label: {
          show: false,
        },
        data: finalChartData?.map(item => ({
          name: item.inverterName,
          value: keepTwoOrLine(item.power),
        })),
      },
      {
        data: finalChartData?.map(item => ({
          name: item.inverterName,
          value: keepTwoOrLine(item.power),
        })),
        type: 'pie',
        percentPrecision: 5,
        padAngle: 1,
        minAngle: 2,
        left: 150,
        top: 60,
        name: '发电量(kWh)',
        radius: [66, 86],
        width: 124,
        height: 124,
        emphasis: {
          disabled: true,
        },
        label: {
          show: false,
        },
        itemStyle: {
          opacity: 0.2,
        },
      },
    ],
  };
};
