import { PermissionsType } from '@/common/permissionsConst';
import SitePerforAnalysisYear from '.';

const routes = [
  {
    path: '/sitePerformance/analysis/year',
    permission: '',
    element: <SitePerforAnalysisYear />,
  },
];
export default routes;
