import React, { useContext, useMemo, useState } from 'react';
import styles from './index.module.scss';
import tittleDecoration from '../../imgs/tittleDecoration.png';
import { Empty, Tabs } from '@maxtropy/components';
import { Spin } from 'antd';
import ReactEcharts from 'echarts-for-react';
import { useRequest } from 'ahooks';
import { apiV2PvPerformanceAnalyseInverterExpressionPost, apiV2PvStationPvAreaListPost } from '@maxtropy/dmes-apis-v2';
import { ResolutionEnum, SitePerforAnalysisYearContext } from '../..';
import { getChartOption, getPieChartOption } from './chart';
import pvIcon from '@/assets/images/pvIcon.png';
import pvArea from '@/assets/images/pvArea.png';
import { formatAreaName, formatAreaNameDot, formatPowerBelowTen } from '../../utils';

const InverterPerformance = () => {
  const { pvName, pvId, resolution, date } = useContext(SitePerforAnalysisYearContext);
  const [activeKey, setActiveKey] = useState<string>('all');

  // 逆变器表现tab
  const { data: tabData } = useRequest(
    () => {
      return apiV2PvStationPvAreaListPost({
        id: pvId,
      });
    },
    {
      ready: !!pvId,
      refreshDeps: [pvId],
    }
  );

  // 逆变器表现整站
  const { data: chartData, loading } = useRequest(
    () => {
      return apiV2PvPerformanceAnalyseInverterExpressionPost({
        id: activeKey === 'all' ? pvId : activeKey,
        type: resolution,
        time: date?.valueOf(),
        powerType: activeKey === 'all' ? 0 : 1,
      });
    },
    {
      ready: !!activeKey && !!pvId && !!date && !!resolution,
      refreshDeps: [activeKey, pvId, date, resolution],
    }
  );

  const tabs = useMemo(() => {
    return [
      {
        label: '整站',
        key: 'all',
      },
    ].concat(
      (tabData?.list ?? []).map(m => ({
        label: m.name ?? '--',
        key: m.id!.toString(),
      }))
    );
  }, [tabData]);

  // 柱状图图表
  const chartBarOption = useMemo(() => getChartOption(chartData, resolution), [chartData, resolution]);

  // 饼图图表
  const chartPieOption = useMemo(() => getPieChartOption(chartData?.inverterDataList), [chartData]);

  const isShowEmpty = useMemo(() => {
    if (chartData?.inverterChartDataList?.length) {
      return false;
    }
    return true;
  }, [chartData]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
        <span className={styles.title}>逆变器表现</span>
      </div>
      <div className={styles.content_top_box}>
        <Tabs activeKey={activeKey} items={tabs} onChange={val => setActiveKey(val)} />
        <div className={styles.chart_box}>
          {loading ? (
            <Spin spinning={true} />
          ) : isShowEmpty ? (
            <Empty className={styles.empty_box} />
          ) : (
            <ReactEcharts style={{ height: 340 }} option={chartBarOption} notMerge lazyUpdate={false} />
          )}
        </div>
      </div>
      <div className={styles.content_bottom_box}>
        <div className={styles.chart_box}>
          {loading ? (
            <Spin spinning={true} />
          ) : isShowEmpty ? (
            <Empty className={styles.empty_box} />
          ) : (
            <ReactEcharts style={{ height: 240 }} option={chartPieOption} notMerge lazyUpdate={false} />
          )}
        </div>
        <div className={styles.desc_box}>
          <div className={styles.line}>
            对于
            <div
              className={styles.name_box}
              style={{ backgroundColor: activeKey === 'all' ? '#16DD8E20' : 'rgba(250,173,20,0.2)' }}
            >
              {activeKey === 'all' ? (
                <>
                  <img src={pvIcon} alt="" style={{ width: 16, height: 16 }} />
                  {pvName}
                </>
              ) : (
                <>
                  <img src={pvArea} alt="" style={{ width: 16, height: 16 }} />
                  {tabs.find(i => i.key === activeKey)?.label}
                </>
              )}
            </div>
          </div>
          <div className={styles.line}>
            {resolution === ResolutionEnum.YEAR ? date?.format('YYYY') + '年' : date?.format('MM') + '月'}
            {formatAreaName(chartData?.maxInverterPower)}发电
            <span className={styles.peak_label}>最多</span>，{formatAreaName(chartData?.minInverterPower)}发电
            <span className={styles.peak_label}>最少</span>，{formatAreaNameDot(chartData?.increaseInverters)}
            发电同比去年有
            <span className={styles.peak_label}>增加</span>，{formatAreaNameDot(chartData?.decreaseInverters)}
            发电同比去年有
            <span className={styles.peak_label}>减少</span>
          </div>
          <div className={styles.line}>
            其中，
            {formatPowerBelowTen(chartData?.belowInverters, resolution, date)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InverterPerformance;
